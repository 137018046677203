/*
 * 업무구분: 안내자료
 * 화 면 명: MSPSA201M
 * 화면설명: 안내자료 홈
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2024.03.19
 * 재개발: sh.park
 */
<template>
  <ur-page-container class="msp" type="subpage" title="안내자료" :show-title="true" :action-type="actionSlot" ref="page" :topButton="true" @on-header-left-click="fn_HistoryBack" @on-scroll="fn_OnPageScrollEvent">
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="row" class="top-btn-area">
        <mo-segment-wrapper solid primary v-model="firstItem">
          <mo-segment-button value="1" class="top-btn" @click="moveScrollTo('top')">내가사용한자료</mo-segment-button>
          <mo-segment-button value="2" class="top-btn" @click="moveScrollTo('middle')">인기자료</mo-segment-button>
          <mo-segment-button value="3" class="top-btn" @click="moveScrollTo('bottom')">최신자료</mo-segment-button>
        </mo-segment-wrapper>
      </ur-box-container>
    </template>

    <ur-box-container direction="column" alignV="start" ref="main">
      <ur-box-container alignV="start" componentid="ur_box_container_010" direction="row" ref="scrollContainer" class="scroll-container">
        <ur-box-container alignV="start" class="title-area-pt7" componentid="ur_box_container_002" direction="column" data-anker="1" ref="top">
          <h3>내가 사용한 자료</h3>
        </ur-box-container>
        <ur-box-container alignV="start" ref="container1" componentid="ur_box_container_003" direction="column" class="ns-scroll-x-list">
          <div class="scroll-list" v-if="lv_myUsedCntntItems.length > 0"  ref="ref1">
            <mo-list :list-data="lv_myUsedCntntItems" horizontal >
              <template #list-item= "{item}">
                <mo-card class="ns-data-item">
                  <div class="mo-card__contents" @click="fn_OnSelectedSingleItem(item)">
                    <div class="mo-card__header">
                      <mo-badge class="card__badge gray" color="primary" :text="item.untySlmtClsfNm01" shape="identifier"></mo-badge>
                      <mo-badge class="card__badge orange" color="primary" text="HOT" shape="identifier" v-if="false"></mo-badge>
                      <div class="mo-card__name">
                        {{item.untySlmtNm}}
                      </div>
                    </div>
                    <div class="mo-card__body">
                      <img :src="item.blobImgUrl"/> 
                    </div>
                  </div>
                  <mo-checkbox class="chk_new--click" v-model="item.checked" @input="fn_CheckClick(item)"></mo-checkbox> 
                </mo-card>
              </template>
            </mo-list>
            <div class="add_box" v-if="!lv_isUsedEmpty" @click="fn_MoreBtnClick('USED')">
              <mo-filter-chip color="normal" icon="add">더보기</mo-filter-chip>
            </div>
          </div>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="no-data" v-else-if="lv_myUsedCntntItems.length === 0 && isAfterFirstSearch === true">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info">
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="title-area-pt12-pb20" ref="middle">
          <h3>인기자료</h3>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_005" ref="container2" direction="column" class="ns-check-list2 bd-T-Ty1" v-if="lv_popuRnkCntntItems.length > 0"> <!-- 퍼블 선겹침 현상 수정용 bd-T-Ty1 -->
          <mo-list :list-data="lv_popuRnkCntntItems">
            <template #list-item= "{item,index}"> 
              <mo-list-item class="bdTN bd-b-Ty1"> <!-- 퍼블 선겹침 현상 수정용 bdTN bd-b-Ty1 -->
                <mo-checkbox class="mt0" v-model="item.checked" @input="fn_CheckClick(item)"/> <!-- 퍼블 체크박스와 bage 줄맞춤용 mt0 -->
                <div class="list-item__contents" ref="ref2" @click="fn_OnSelectedSingleItem(item)">
                  <div class="list-item__contents__second">
                    <mo-badge class="badge-sample-badge gray" :text="item.untySlmtClsfNm01" shape="status"></mo-badge>
                    <mo-badge class="badge-sample-badge orange" text="HOT" shape="status" v-if="index < 3">HOT</mo-badge><!---->
                  </div>
                  <div class="list-item__contents__title">
                    <span class="name txtSkip">{{item.untySlmtNm}}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span>{{$commonUtil.dateDashFormat(item.untySlmtRegDtm)}}</span><em>|</em>
                    <span>조회 {{item.inqrFgr}}</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
          
          <mo-filter-chip  color="normal" icon="add" v-if="!lv_isPopEmpty" @click="fn_MoreBtnClick('POP')">더보기</mo-filter-chip>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="no-data" v-else-if="lv_popuRnkCntntItems.length === 0 && isAfterFirstSearch === true">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="title-area-pt7" data-anker="3" ref="bottom">
          <h3>최신자료</h3>
        </ur-box-container>
        <ur-box-container alignV="start" ref="container3" componentid="ur_box_container_007" direction="column" class="ns-scroll-x-list mb55">
          <div class="scroll-list" ref="ref3" v-if="lv_RntCntntItems.length > 0">
            <mo-list :list-data="lv_RntCntntItems" horizontal>
              <template #list-item= "{item}">
                <mo-card class="ns-data-item">
                  <mo-checkbox v-model="item.checked" @input="fn_CheckClick(item)"></mo-checkbox>
                  <div class="mo-card__contents">
                    <div class="mo-card__header" @click="fn_OnSelectedSingleItem(item)">
                      <mo-badge class="card__badge gray" color="primary" :text="item.untySlmtClsfNm01" shape="identifier"></mo-badge>
                      <div class="mo-card__name">
                        {{item.untySlmtNm}}
                      </div>
                    </div>
                    <div class="mo-card__body"><img :src="item.blobImgUrl"></div>
                  </div>
                </mo-card>
              </template>
            </mo-list>
            <div class="add_box" v-if="!lv_isRntEmpty" @click="fn_MoreBtnClick('RNT')">
              <mo-filter-chip color="normal" icon="add">더보기</mo-filter-chip>
            </div>
          </div>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="no-data" v-else-if="lv_RntCntntItems.length === 0 && isAfterFirstSearch === true">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info">
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>

        </ur-box-container>

        <!-- 바텀시트 영역 -->
        <ur-box-container  alignV="start" componentid="" direction="column" class="msp-sa-wrap" > <!-- 퍼블 이벤트 모듈을 감싸는 class 를 기존 소스에 반영하기 위해 msp-sa-wrap -->
          <mo-bottom-sheet ref="bs10" class="ns-bottom-sheet ns-style3 msp-bottomSt-alert only-btn"><!-- 퍼블 msp-bottomSt-alert 추가  __prevent-back-close -->   
            <template v-slot:action>
              <div class="action-area">
                <ur-box-container align-v="start" componentid="" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs pt0">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white ls--2" :disabled="lv_IsSelectedLibraryContent ||  lv_IsSelectedSmsContent" @click="fn_OpenMSPSA222P()">화상상담 자료저장</mo-button>
                    <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="lv_IsSelectedSmsContent"  @click="fn_ReadySelectedItemsViewDetails">상세보기</mo-button>
                  </div>
                </ur-box-container>
              </div>
              <ur-box-container componentid="" direction="row">
                <div class="btn_icon_box--jjh">
                  <mo-button componentid="" class="ns-btn-round message" :disabled="lv_IsSelectedLibraryContent"  @click="fn_SMSBtnClicked()">문자</mo-button>
                  <mo-button componentid="" class="ns-btn-round email_link2" :disabled="lv_IsSelectedLibraryContent ||  lv_IsSelectedSmsContent"  @click="fn_OpenPopEmailCustList()">이메일</mo-button>
                  <mo-button componentid="" class="ns-btn-round print2" :disabled="lv_IsSelectedLibraryContent ||  lv_IsSelectedSmsContent"  @click="fn_print_BottomSheet_open()">인쇄</mo-button>
                  <mo-button componentid="" class="ns-btn-round scrap" :disabled="lv_IsSelectedLibraryContent ||  lv_IsSelectedSmsContent"  @click="fn_OpenMSPSA210P()" >스크랩</mo-button>
                </div>
              </ur-box-container>
            </template>
          </mo-bottom-sheet>   

          <mo-bottom-sheet ref="print_bottomSheet"  class="ns-bottom-sheet closebtn" preventTouchClose>
            <template v-slot:title>
              인쇄
              <div class="ns-btn-close" @click="fn_print_BottomSheet_close()" name="닫기"></div>
            </template>
    
            <ur-box-container direction="column" alignV="start">        
              <ur-box-container componentid="namecard">          
                <ur-box-container componentid="" direction="row" class="fexTy5 pl24 mb30">
                  <span class="mr35">매수</span>
                  <mo-stepper v-model="lv_SpinValue" :value=1 :min="1" :max="20"/>
                </ur-box-container>
              </ur-box-container>
            </ur-box-container>

            <template v-slot:action>
              <ur-box-container align-v="start" componentid="" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs pt0">
                <div class="relative-div">
                  <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_print_BottomSheet_close()">취소</mo-button>
                  <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OzConfirmBtnClicked()">인쇄</mo-button>
                </div>
              </ur-box-container>
            </template>
          </mo-bottom-sheet> 
        </ur-box-container>
      
        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
        <div class="ns-height100"></div>

      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'

import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import DateUtil from '@/ui/sp/common/exDateUtils'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import SaConstants from '@/config/constants/saConstants'
import Screen from '~systems/mixin/screen'

import MSPSA206P from '@/ui/sa/MSPSA206P.vue' // 안내자료 카테고리
import MSPSA210P from '@/ui/sa/MSPSA210P.vue' // 안내자료 스크랩
import MSPSA222P from '@/ui/sa/MSPSA222P.vue' // 화상상담 자료저장
import MSPSA063P from '@/ui/sa/MSPSA063P.vue' // 화상상담 자료
import MSPCM128P from '@/ui/cm/MSPCM128P.vue' // 수신고객목록
import MSPCM152P from '@/ui/cm/MSPCM152P.vue' // 고객검색
import MSPCM154P from '@/ui/cm/MSPCM154P.vue' // 터치On 문자발송

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPSA201M',
  // 현재 화면 ID
  screenId: 'MSPSA201M',
  mixins: [Screen],

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      isAfterFirstSearch: false,
      firstItem: '1',
      lv_UntySlmtUseAuthCd: '1%', // 통합영업자료사용 권한코드
      // 대분류 목록 -> 홈, DM 등
      lv_UntySlmtClsf: [],
      
      // 내가사용한자료 5건
      lv_myUsedCntntItems: [],
      // 내가사용한자료 유무
      lv_isUsedEmpty: true,
      // 내가사용한자료 선택리스트
      lv_SelectedUsedList: [],

      // 인기자료 5건
      lv_popuRnkCntntItems: [],
      // 인기자료 유무
      lv_isPopEmpty: true,
      // 인기자료 선택리스트
      lv_SelectedPopuList: [],

      // 최신 자료 전체 목록
      lv_RntCntntItems: [],
      // 최신자료 유무
      lv_isRntEmpty: true,
      // 최신자료 선택리스트
      lv_SelectedRntList: [],

      // 단건 자료 선택
      lv_SelectedSingleItem: null,
      // 공용 선택된 아이템 리스트, child의 리스트들과 동기화 됨
      lv_SelectedItemList: [],
      // 다건 자료 선택,
      lv_IsMadeMultiItem: true,
      // PDF 파일 생성 VO 목록
      lv_SADCFmlmtSVOList: [],
      // PDF 생성 완료된 파일 정보를 저장하는 Array
      lv_CompletedPDFFileVOList: [],
      // PDF 생성 여부 판단 반복 횟수
      lv_CheckDocsRetryNumberOfTimes: 0,
      // 선택된 FOM 리스트
      lv_SelectedFomItemList: [],
      lv_SelectedItemListTmp:[],
      // swipe-tap 에서 선택된 대분뷰명=> 라이브러리 or 사랑On도움방 대분류 선택시 안드로이드 네이티브 pdf뷰에서 "SMS" 메뉴 안보이기 
      lv_SelcectedClsfSytmNm: '',
      // 대분류명이 "라이브러리" or "사랑On도움방" 일때 "문자전송" 버튼을 disabled 시킨다.
      lv_IsSelectedLibraryContent: false,
      // 대분류명이 "동영상 일때 "문자전송" 버튼을 disabled 시킨다.
      lv_IsSelectedSmsContent: false,
      // 선택된 카테고리
      lv_SelectedCateObject: {},
      // nextKey방식의 페이징 변수
      lv_rntCntntStndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}],
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}],
      // 고객목록 Object
      custListObj: {},

      scrollAnimate: null,
      actionSlot:{},
      clickedSingleItem: false,

      lv_TargetType: 'c',
      lv_SelectedCustList: [], // 화상상담자료저장 고객목록 리스트
      lv_SpinValue: 1,         // 인쇄매수

      isNofacCnsl: false,  // 비대명 상상 관련 
    }
  },

  /***********************************************************************************
   * computed 정의 영역                                                              *
  ***********************************************************************************/
  computed: {
    fn_IsLib() { // 라이브러리
      let libraryItemCount = 0
      if (this.lv_IsMadeMultiItem) {
        this.lv_SelectedItemList.forEach(item => {
          if (item.untySlmtClsfNm01.indexOf(SaConstants.LIBRARY_CATE_NM) > -1) {
            libraryItemCount++
          }
        })
      } else {
        if (this.lv_SelectedSingleItem.untySlmtClsfNm01.indexOf(SaConstants.LIBRARY_CATE_NM) > -1) {
          libraryItemCount++
        }
      }
      return libraryItemCount > 0
    },
    fn_IsLOHelp() { // 사랑온 도움방
      let libraryItemCount = 0
      if (this.lv_IsMadeMultiItem) {
        this.lv_SelectedItemList.forEach(item => {
          if (item.untySlmtClsfNm01.indexOf(SaConstants.LOVEONHELP_CATE_NM) > -1) {
            libraryItemCount++
          }
        })
      } else {
        if (this.lv_SelectedSingleItem.untySlmtClsfNm01.indexOf(SaConstants.LOVEONHELP_CATE_NM) > -1) {
          libraryItemCount++
        }
      }
      return libraryItemCount > 0
    },
    /*********************************************************
     * @notice menuAuth: 0: hide / 1: show
    *********************************************************/
    fn_CmptdPDF() {
      let rParam = {path: '/sli/tss/comm/fmlmt', file: '', menuAuth: '1111', lv_IsDownloadBtn: 'Y'}
      
      // 콜백 함수 정의
      rParam.callbackFnc = {
        fn_Sms: this.fn_SMSBtnClicked,
        fn_Email: this.fn_OpenPopEmailCustList,
        fn_Print: this.fn_print_BottomSheet_open,
        fn_Scrap: this.fn_OpenMSPSA210P
      }

      // 대분류명이 "라이브러리" 형태가 아닌 "(p)" 등이 끝에 붙어올수 있다. 이를 고려함.
      // lv_SelcectedClsfSytmNm 변수는 swipe 탭에서 "라이브러리" or "사랑On도움방" 탭을 선택후 pdf 뷰 보기를 할경우임.
      if (this.fn_IsLib || this.fn_IsLOHelp) { // 테블릿과 별도 구현, 동기화시 주의!!!!!
       rParam.menuAuth = '0000' // 안드로이드 pdf 뷰에서 "SMS" 메뉴 숨기기(다중 선택시 "라이브러리" or "사랑On도움방" 포함되면 선택된 모든 파일에 대해서도 보이지 않음)
       rParam.lv_IsDownloadBtn = 'N' // PDF 저장 불가
      }

      return rParam
    },

    fn_CmptAge() {
      let age = 0
      let years = 0
      let months = 0
      let birthDate = this.custListObj[0].custList[0].dob
      let contractDate = moment(new Date().toISOString()).format('YYYYMMDD')
      let fullMonths = 0
      if (parseInt(contractDate.substr(0,4)) < 1988) {
        fullMonths = DateUtil.getMonthPeriodBySingleCalc(birthDate, contractDate)
      } else {
        fullMonths = DateUtil.getPassedMonthCount(birthDate, contractDate)
      }

      years = parseInt(fullMonths / 12)
      months = parseInt(fullMonths % 12)
      age = months > 5 ? years + 1 : years

      return age
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  created () {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    
    //통합영업자료사용권한코드
    this.lv_UntySlmtUseAuthCd = this.$saUtil.fn_SetUntySlmtUseAuthCd()
    // 분류목록 조회
    this.fn_SelCate()
  },

  activated () {
    setTimeout(()=> {
      document.getElementById('scroll_MSPSA201M').scrollTop = this.scrollPosition
    }, 300)
  },

  mounted () {
    const lv_Vm = this

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    // 스크롤
    this.scrollAnimate = createScrollAnimateInstance(document.getElementById("scroll_MSPSA201M"), 'y')

    // 상단 검색, 카테고리 버튼 생성
    this.actionSlot = {
      actionComponent: {
        template:'<span><mo-button style="margin-right: 13px" shape="border" class="ns-btn-category" @click="clickCategory()">분류별보기</mo-button><mo-icon style="bottom: 3px" @click="clickSearch">search</mo-icon></span>',
        methods: {
          clickCategory() {
            lv_Vm.fn_OpenCate();
          },
          clickSearch() {
            lv_Vm.fn_SearchProd()
          }
        }
      }
    }
  },

  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    this.$BottomManager.fn_SetBottomVisible(true)
  },
      
  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    moveScrollTo(compo) {
      const target = this.$refs[compo];
      this.scrollAnimate.animate(target.$el.offsetTop, 700, 0, 'easeOut');
    },
    
    /*********************************************************
     * @function  fn_SelCate
     * @notice    분류 목록 조회
     * Params:    N/A
     * Return:    대분류/중분류/소분류 구조의 전체 분류목록
    *********************************************************/
    fn_SelCate() {
      const lv_Vm = this

      // 업무config의 분류목록 중 안내자료 분류목록 최상단의 키값을 파라미터로 설정 'SACLSFS'라는 값은 스마트폰의 대,중,소 분류를 가져오기 위함.
      let pParams = { clsfSytmId: 'SACLSFS' } // 테블릿. 'SACLSFT'

      // 한번에 안내자료의 분류목록을 모두 읽음.
      const trnstId = 'txTSSSA01S4'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then( response => {
          if (this.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') {
              let responseBody = response.body
              // 전체 분류목록 저장
              lv_Vm.lv_UntySlmtClsf = responseBody.sALClsfSytmCntntVO
              // 카테고리 생성
              lv_Vm.setCateMenu()
              // 안내자료 홈을 제외한 화면에서는 Vuex에 저장된 분류 목록으로 화면 구성
            }
          }
        }, (error) => {
          return Promise.reject(error)
        })
        .catch( (error) => {
          window.vue.error(error)
        })
    },

    /*********************************************************
     * @function  fn_SelMyUsedUntySlmtCntnt
     * @notice    내가 사용한 자료 최초 조회
     * Params:    N/A
     * Return:    내가 사용한 자료 컨텐츠 목록
    *********************************************************/
    fn_SelMyUsedUntySlmtCntnt() {
      const lv_Vm = this
      
      lv_Vm.isAfterFirstSearch = false

      let pParams = {
        untySlmtUseAuthCd: lv_Vm.lv_UntySlmtUseAuthCd, // 통합영업자료사용권한코드(전체/FC/AM/LT/GFC/방카)
        whlInqrYn: 'Y', // 전체조회여부
        moblInqrYn: 'Y', // 모바일에서 호출여부
        cnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId // 컨설턴트 번호
      }

      const trnstId = 'txTSSSA01S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then( response => {
          lv_Vm.isAfterFirstSearch = true
          
          if (this.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '' && response.body.iCCntntBasInfoVO !== null && response.body.iCCntntBasInfoVO.length > 0) {
              let reponseBody = response.body
              reponseBody.iCCntntBasInfoVO.forEach(async (item) => {

                item.blobImgUrl = ''
                if (process.env.NODE_ENV === 'local') {
                  item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                } else if (process.env.NODE_ENV === 'development') {
                  item.blobImgUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=/img_1.png'
                } else {
                  let responseObj = null
                  if (item.thumApndFilePathNm !== null && item.thumApndFilePathNm !== '' && item.thumApndFileStoreNm !== null && item.thumApndFileStoreNm !== '') {
                    let strUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=' + item.thumApndFilePathNm + '/' + item.thumApndFileStoreNm
                    responseObj = await this.$bizUtil.httpUrlCall(strUrl)
                  }
                  if (responseObj) {
                    item.blobImgUrl = responseObj
                  } else {
                    item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                  }
                }
              })

              // 검색 후, 전체 데이터를 다시 보여주기 위해 저장
              lv_Vm.lv_myUsedCntntItems = reponseBody.iCCntntBasInfoVO.slice(0, 5)

              // "더 보기(+)" 버튼을 보이기 위해 해당 항목이 비어있는 지 확인
              lv_Vm.lv_isUsedEmpty = _.isEmpty(lv_Vm.lv_myUsedCntntItems)

              // 데이터가 응답(완료)되었으므로 false
              lv_Vm.lv_isMyUsedCntntLoading = false
            }
          } else {
            // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_Vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        }, (error) => {
          return Promise.reject(error)
        })
        .catch( (error) => {
          window.vue.error(error)
        })
    },

    /*********************************************************
     * @function  fn_SelPopuRnkCntnt
     * @notice    인기자료 최초 조회
     * Params:    N/A
     * Return:    인기자료 컨텐츠 목록
    *********************************************************/
    fn_SelPopuRnkCntnt() {
      const lv_Vm = this

      let pParams = {
        untySlmtUseAuthCd: lv_Vm.lv_UntySlmtUseAuthCd, // 통합영업자료사용권한코드(전체/FC/AM/LT/GFC/방카)
        inqrScVal: '3', // 기간 구분값(1개월:1, 3개월:2)
        moblInqrYn: 'Y' // 모바일에서 호출여부
      }

      const trnstId = 'txTSSSA01S2'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then( response => {
          if (this.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') {
              let reponseBody = response.body
              // 검색 후, 전체 데이터를 다시 보여주기 위해 저장
              lv_Vm.lv_popuRnkCntntItems = reponseBody.iCCntntBasInfoVO.slice(0, 5)

              // "더 보기(+)" 버튼을 보이기 위해 해당 항목이 비어있는 지 확인
              lv_Vm.lv_isPopEmpty = _.isEmpty(lv_Vm.lv_popuRnkCntntItems)
            }
          } else {
            // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_Vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        }, (error) => {
          return Promise.reject(error)
        })
        .catch( (error) => {
          window.vue.error(error)
        })
    },

    /*********************************************************
     * @function  fn_SelRntCntnt
     * @notice    최신자료 최초 조회
     * Params:    N/A
     * Return:    최신자료 컨텐츠 목록
    **********************************************************/
    fn_SelRntCntnt() {
      const lv_Vm = this

      let pParams = {
        untySlmtUseAuthCd: lv_Vm.lv_UntySlmtUseAuthCd, // 통합영업자료사용권한코드(전체/FC/AM/LT/GFC/방카)
        whlInqrYn: 'Y', // 전체조회여부
        moblInqrYn: 'Y' // 모바일에서 호출여부
      }

      const trnstId = 'txTSSSA01S1'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then( response => {          
          if (this.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '' && response.body.iCCntntBasInfoVO !== null && response.body.iCCntntBasInfoVO.length > 0) {
              let reponseBody = response.body

              reponseBody.iCCntntBasInfoVO.forEach(async (item) => {
                item.blobImgUrl = ''

                if (process.env.NODE_ENV === 'local') {
                  item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                } else if (process.env.NODE_ENV === 'development') {
                  item.blobImgUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=/img_1.png'
                } else {
                  let responseObj = null
                  if (item.thumApndFilePathNm !== null && item.thumApndFilePathNm !== '' && item.thumApndFileStoreNm !== null && item.thumApndFileStoreNm !== '') {
                    let strUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=' + item.thumApndFilePathNm + '/' + item.thumApndFileStoreNm
                    responseObj = await this.$bizUtil.httpUrlCall(strUrl)
                  }
                  if (responseObj) {
                    item.blobImgUrl = responseObj
                  } else {
                    item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                  }
                }
              })

              // 서비스 통신 조회키
              lv_Vm.lv_rntCntntStndKeyList[0] = response.trnstComm.stndKeyList[0]

              // 검색 후, 전체 데이터를 다시 보여주기 위해 저장 , 이때 5개만 보여준다.
              lv_Vm.lv_RntCntntItems = reponseBody.iCCntntBasInfoVO.slice(0, 5)

              // "더 보기(+)" 버튼을 보이기 위해 해당 항목이 비어있는 지 확인
              lv_Vm.lv_isRntEmpty = _.isEmpty(lv_Vm.lv_RntCntntItems)

              lv_Vm.lv_isRntCntntLoading = false
            }
          } else {
            // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            this.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        }, (error) => {
          return Promise.reject(error)
        })
        .catch( (error) => {
          window.vue.error('TSSSP201M_fn_onInitRntCntnt ERROR: ' + error)
        })
    },

    /******************************************************************************
     * @function  fn_IncrementItemInqrFgrList
     * @notice    조회 이력 저장
    ******************************************************************************/
    fn_IncrementItemInqrFgrList (video) {
      const lv_Vm = this

      let sParams = {
        cnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId
      }

      if (lv_Vm.lv_IsMadeMultiItem) {
        sParams.sACntntBasInfoSVO = lv_Vm.lv_SelectedItemList
      } else {
        let tempVOList = []
        tempVOList.push(lv_Vm.lv_SelectedSingleItem)
        sParams.sACntntBasInfoSVO = tempVOList
      }

      const trnstId = 'txTSSSA01P2'
      const auth = 'S'
      this.post(lv_Vm, sParams, trnstId, auth)
        .then( response => {
          // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
          lv_Vm.getStore('progress').getters.getState.isShow = true

          if (this.$commonUtil.isSuccess(response)) {
            if (!_.isEmpty(response.body) && !_.isEmpty(response.body.sASelCntntDtlSVO)) {
              if (response.body.sASelCntntDtlSVO.length === sParams.sACntntBasInfoSVO.length) {

                let selectedSingleItem = null

                // 선택 파일이 단일 파일이면서, 동영상 파일일 경우는 외부 링크 연결, 그렇지 않을 경우는 PDF Viewer 실행
                if (!lv_Vm.lv_IsMadeMultiItem) {
                  selectedSingleItem = lv_Vm.lv_SelectedSingleItem
                } else {
                  if (lv_Vm.lv_SelectedItemList.length === 1) {
                    selectedSingleItem = lv_Vm.lv_SelectedItemList[0]
                  }
                }

                if (!_.isEmpty(selectedSingleItem) && !this.$bizUtil.isEmpty(selectedSingleItem.vdoLnkUrl)) {
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                  lv_Vm.getStore('progress').getters.getState.isShow = false
                  window.fdpbridge.exec('intentBrowserPlugin', {uri: selectedSingleItem.vdoLnkUrl}, () => {}, () => {})
                } else {
                  lv_Vm.fn_SaveFOMFile()
                }

              }
            } else {
              // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
              lv_Vm.getStore('progress').getters.getState.isShow = false
              if (response.msgComm.msgCd !== null && response.msgComm.msgCd.length > 0) {
                lv_Vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
              }
            }
          }
        })
        .catch( (error) => {
          // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
          lv_Vm.getStore('progress').getters.getState.isShow = false
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * @function  fn_CreateToPDFContnt
     * @notice    컨설턴트 정보 조회(전문 생성을 위해)
     * Params:    lv_UserInfo 사용자 정보 데이터 (Object)
     * Return:    void
    ******************************************************************************/
    fn_CreateToPDFContnt () {
      const lv_Vm = this

      lv_Vm.lv_SADCFmlmtSVOList = []
      lv_Vm.lv_CompletedPDFFileVOList = []

      let pParams = {
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId
      }

      // FOM 파일만 PDF 생성 대상
      pParams.sACntntBasInfoSVO = lv_Vm.lv_IsMadeMultiItem ? lv_Vm.lv_SelectedFomItemList : [lv_Vm.lv_SelectedSingleItem]

      const trnstId = 'txTSSSA22P3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then( response => {
          if (this.$commonUtil.isSuccess(response)) {
            if (!_.isEmpty(response.body) && !_.isEmpty(response.body.sADCFmlmtSVO)) {
              lv_Vm.lv_SADCFmlmtSVOList = response.body.sADCFmlmtSVO
              lv_Vm.lv_CheckDocsRetryNumberOfTimes = 0 // PDF 생성여부 확인 조회 회수 초기화

              lv_Vm.fn_CheckContentsTransformation(lv_Vm.lv_SADCFmlmtSVOList)
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            this.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        })
        .catch( (error) => {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * @function  fn_CheckContentsTransformation  ( 2차 개발시 메서드 대체 처리 )
     * @notice    FOM 파일을 PDF 파일로 변환 요청 후 요청한 PDF 생성 여부 확인
     * Params:    변환 요청한 결과 VO 목록
     * Return:    N/A
    ******************************************************************************/
    fn_CheckContentsTransformation (pSADCFmlmtSVO) {
      const lv_Vm = this

      let pParams = {
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
        sADCFmlmtSVO: pSADCFmlmtSVO
      }

      const trnstId = 'txTSSSA22P4'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then( response => {
          if (this.$commonUtil.isSuccess(response)) {
            if (!_.isEmpty(response.body)) {
              // 생성여부 확인 시도 회수를 증가
              // 20번까지만 생성확인 여부 시도
              lv_Vm.lv_CheckDocsRetryNumberOfTimes++

              // FOM 파일 목록에 대한 생성여부를 결과 VO로 리턴
              // VO로 리턴된 FOM 파일 이외의 FOM파일은 계속 생성 여부를 확인
              if (!_.isEmpty(response.body.sADCDoctTrfoBasSVO)) {
                let resultSADCDoctTrfoBasSVO = response.body.sADCDoctTrfoBasSVO.slice()

                let tempVO = {}
                let cmpltVO = {}
                
                // 생성요청 VO 목록에서
                lv_Vm.lv_SADCFmlmtSVOList.forEach(dcItem => {
                  // 생성여부 확인된 VO 목록의 결과를 비교하여 일치하는 VO가 있을 경우
                  tempVO = resultSADCDoctTrfoBasSVO.filter(item => dcItem.srcFileNm01 === item.eltrnDoctId)[0]
                  
                  // 생성여부 확인 VO를 생성여부 확인된 VO만 별도로 저장하고 있는 VO에 저장
                  if (!_.isEmpty(tempVO)) {
                    cmpltVO = lv_Vm.lv_CompletedPDFFileVOList.filter(item => tempVO.eltrnDoctId === item.eltrnDoctId)
                    if (_.isEmpty(cmpltVO)) lv_Vm.lv_CompletedPDFFileVOList.push(tempVO)
                  }

                })

                // 생성여부 확인이 끝난 FOM 파일만 저장하고 있는 Array와 생성요청 FOM 파일 정보를 담고 있는 VO 목록이 일치하지 않으면
                // 생성여부 확인 작업을 계속 진행
                if (lv_Vm.lv_CompletedPDFFileVOList.length !== lv_Vm.lv_SADCFmlmtSVOList.length) {
                  // 20번 시도 회수보다 작으면 생성여부 확인 작업을 계속 시도
                  if (lv_Vm.lv_CheckDocsRetryNumberOfTimes < 20) {
                    setTimeout(function () {
                      lv_Vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                    }, 1000)
                  }
                } else { // 그렇지 않으면 조회수 증가 단계로 이동
                  // 조회이력 증가 호출
                  if (lv_Vm.isNofacCnsl) { // [비대면상담]인 경우는 TUTU00012, TUTU00013 테이블에 상담 문서 정보 INSERT
                      let tempObj = {}
                      for (let i = 0; i < pSADCFmlmtSVO.length; i++) {
                        tempObj = {}
                        tempObj.nofacCnslDoctNm = pSADCFmlmtSVO[i].prdtNm
                        tempObj.eltrnDoctFilePathNm = response.body.sADCDoctTrfoBasSVO[i].eltrnDoctFilePathNm
                        tempObj.eltrnDoctFileNm = response.body.sADCDoctTrfoBasSVO[i].eltrnDoctFileNm
                        lv_Vm.lv_SelectedPdfList.push(tempObj)
                      }
                      lv_Vm.fn_InsNofacCnslData(lv_Vm.lv_SelectedPdfList)
                  } else { // 조회이력 증가 호출
                    lv_Vm.fn_IncrementItemInqrFgrList()
                  }
                }
              } else if (lv_Vm.lv_CheckDocsRetryNumberOfTimes < 20) {
                setTimeout(_ => {
                  lv_Vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                }, 1000)
              } else {
                // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                lv_Vm.getStore('progress').getters.getState.isShow = false
                // 조회이력 증가 호출
                lv_Vm.getStore('confirm').dispatch('ADD', 'PDF 변환에 실패하였습니다.')
              }
            } else { // 서비스 호출 결과는 없지만
              // 변환 완료된 PDF 목록과 변환 요청 PDF 목록 개수가 일치하지 않을 경우 변화여부 확인 요청 시도
              if (lv_Vm.lv_CompletedPDFFileVOList.length !== lv_Vm.lv_SADCFmlmtSVOList.length) {
                setTimeout(_ => {
                  lv_Vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                }, 1000)
              }
            }
          }
        })
        .catch( (error) => {
          window.vue.error(error)
        })

    },
      
    /******************************************************************************
     * @function  fn_SaveFOMFile
     * @notice    단 건의 첨부파일을 실행(저장/오픈)한다.
    ******************************************************************************/
    async fn_SaveFOMFile () {
      const lv_Vm = this

      let savePdfTargetItemList = []
      let viewerTargetFileList = ''
      let itemObj = {}

      let selectedUntySlmtItem = null
      if (this.lv_IsMadeMultiItem) {
        selectedUntySlmtItem = this.lv_SelectedItemList
      } else {
        selectedUntySlmtItem = [this.lv_SelectedSingleItem]
      }

      selectedUntySlmtItem.forEach(selectedUntySlmtItem => {
        itemObj = {} // 초기화

        if (selectedUntySlmtItem.pdfFileYn === 'Y') {
          itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
          itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
          itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
          itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

          // itemObj VO 저장
          savePdfTargetItemList.push(itemObj)
        } else {
          let tempDcFmlmtSVO = null
          let tempCompletedPdfSVO = null

          tempDcFmlmtSVO = lv_Vm.lv_SADCFmlmtSVOList.filter(dcFmlmtSVO => selectedUntySlmtItem.untySlmtId === dcFmlmtSVO.untySlmtId)[0]
          if (!_.isEmpty(tempDcFmlmtSVO)) {
            tempCompletedPdfSVO = lv_Vm.lv_CompletedPDFFileVOList.filter(completedPDFFileSVO => tempDcFmlmtSVO.srcFileNm01 === completedPDFFileSVO.eltrnDoctId)[0]
          }

          if (!_.isEmpty(tempCompletedPdfSVO)) {
            // PDF 확장자 없을경우
            if (tempCompletedPdfSVO.eltrnDoctId.indexOf('.pdf') < 0) {
              tempCompletedPdfSVO.eltrnDoctId = tempCompletedPdfSVO.eltrnDoctId + '.pdf'
            }
  
            // FOM 파일을 PDF로 변환했을 경우는 .pdf 확장자를 붙여서 파일을 다운받도록 설정
            let tempDateDir = tempCompletedPdfSVO.lstTrtDtm.slice(0, 10).replace(/-/g, '')
  
            itemObj.eltrnDoctFilePathNm = tempCompletedPdfSVO.eltrnDoctFilePathNm
            itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
            itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId
            itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId

            // itemObj VO 저장
            savePdfTargetItemList.push(itemObj)
          }
        }
      })

      // 로컬 테스트: PDF 다운로드 Plugin 패스용도
      if (process.env.NODE_ENV === 'local') {
        let pParam = this.fn_CmptdPDF
        savePdfTargetItemList.forEach(item => {viewerTargetFileList += item.eltrnDoctFileNm + 'tssB' + item.fileTitle + 'tssA'})
        pParam.fileList = viewerTargetFileList
        this.$saUtil.fn_CallPdfViewer(lv_Vm, pParam)

        return
      }

      // PDF 저장 실행
      let totCnt = lv_Vm.lv_SelectedItemList.length
      let sucsCnt = 0
      let tryCnt = 0
      for(let i=0; i<savePdfTargetItemList.length; i++) {
        try {
          await this.$saUtil.fn_FdpSaveFile(savePdfTargetItemList[i])
            .then(response => {
              sucsCnt++
              tryCnt++
              viewerTargetFileList += response + 'tssB' + savePdfTargetItemList[i].fileTitle + 'tssA'
            }).catch( (error) => { // 파일 다운로드 실패 콜백
              window.vue.error(error)

              // 화면 깜박거림을 방지하기 위한 로딩바를 해제
              window.vue.getStore('progress').getters.getState.isShow = false
              tryCnt++

              // 자료 선택 건수에 실패 건수를 오류 메시지로 출력
              this.$addSnackbar('총 ' + totCnt + '건 중 ' + (tryCnt - sucsCnt) + '건 저장이 실패하였습니다')
            })
          } catch (error) {
            window.vue.error(error)
          }
      } // for i

      let pParam = lv_Vm.fn_CmptdPDF
      pParam.fileList = viewerTargetFileList

      if (!lv_Vm.lv_IsMadeMultiItem) { // 단일 선택일 경우는 바로 PDF Viewer 호출
        this.$saUtil.fn_CallPdfViewer(lv_Vm, pParam)
      } else {
        if (sucsCnt > 0) {
          this.$saUtil.fn_CallPdfViewer(lv_Vm, pParam)
        } 
        if (tryCnt > sucsCnt) {
          this.$addSnackbar('총 ' + totCnt + '건 중 ' + (tryCnt - sucsCnt) + '건 저장이 실패하였습니다')
        }
      }
    },
    
    /******************************************************************************
     * @function  fn_OnSelectedSingleItem
     * @notice    선택된 인기자료/최신자료/내가사용한자료의 제목부분을
                  클릭한경우 상세보기 팝업창을 띄우지 않고 바로 해당 PDF View를 보여준다.
     * Params:    target
     * Return:    N/A
    ******************************************************************************/
    fn_OnSelectedSingleItem (pSelectedItem) {
      this.clickedSingleItem = true
      this.lv_SelectedSingleItem = pSelectedItem
      
      this.lv_SelectedItemList = [pSelectedItem] // 상세보기 - 더보기 시 사용


      // 단일 선택 후 상세보기 진행 시
      this.lv_IsMadeMultiItem = false
      if (!this.$bizUtil.isEmpty(pSelectedItem.vdoLnkUrl)) {
        // 동영상 링크일 경우 해당 경로를 이용해 브라우저 호출
        this.fn_video_bottomsheet_open()  //'동영상 실행시 데이터 요금이 발생될수 있으니 유의 바랍니다')
      } else if (pSelectedItem.pdfFileYn === 'Y') {
        this.fn_IncrementItemInqrFgrList()
      } else {
        // 동영상 링크가 아닐 경우는 인쇄 실행
        this.fn_CreateToPDFContnt()
      }

      // FOM 파일 저장
      this.lv_SelectedFomItemList.push(pSelectedItem)
    },

    /******************************************************************************
     * @function  fn_ReadySelectedItemsViewDetails
     * @notice    안내자료_홈 화면에서 썸네일 또는 타이틀 , 인기자료 항목 선택시 PDF 보기
    ******************************************************************************/
    fn_ReadySelectedItemsViewDetails () {
      // 동영상 자료 단건 선택 후 '상세보기' 버튼 클릭 시는 조회수 증가 후 URL 이동
      if (this.lv_SelectedItemList.length === 1) {
        this.lv_IsMadeMultiItem = false
        if (!this.$bizUtil.isEmpty(this.lv_SelectedItemList[0].vdoLnkUrl)) {
          this.fn_IncrementItemInqrFgrList()
          return
        }
      }

      // 동영영 자료 선택 여부 판단
      let vodItemList = []
      vodItemList = this.lv_SelectedItemList.filter(item => !this.$bizUtil.isEmpty(item.vdoLnkUrl))

      // 동영상 자료 상세보기 진행불가
      if (!_.isEmpty(vodItemList)) {
        this.$addSnackbar('동영상 자료는 상세보기가 불가합니다.\n동영상자료 선택 해제 후 다시 진행해 주세요..')
        return
      }
      
      // 다중 선택 후 상세보기 진행시
      this.lv_IsMadeMultiItem = true
      this.lv_SelectedFomItemList = [] // PDF로 변환될 FOM 파일 저장 목록 초기화
    
      this.lv_SelectedItemList.forEach(item => {
        if (item.pdfFileYn !== 'Y') {
          this.lv_SelectedFomItemList.push(item) // FOM 파일 저장
        }
      })

      if (this.lv_SelectedFomItemList.length > 0) {
        this.fn_CreateToPDFContnt()
      } else {
        this.fn_IncrementItemInqrFgrList()
      }
    },

    /*********************************************************
     * @function  fn_SMSBtnClicked
     * @notice    문자전송 버튼 클릭
    *********************************************************/
    fn_SMSBtnClicked() {
      const lv_Vm = this

      if (this.lv_SelectedItemList.length > 5) {
        this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
        return
      }

      lv_Vm.fn_OpenPopSmsCustList()
    },

    /*********************************************************
     * @function  setCateMenu
     * @notice    카테고리 메뉴 세팅후 자료조회 컨트롤
    *********************************************************/
    setCateMenu () {
      const lv_Vm = this

      //스토어에 카테고리 등록 (As-Is의 시점차이로 인한 위치변경)
      lv_Vm.getStore('spStore').dispatch('REGISTER', lv_Vm.lv_UntySlmtClsf)

      //카테고리 세팅후
      //내가사용한 자료 조회
      this.fn_SelMyUsedUntySlmtCntnt()
      //인기 자료 조회
      this.fn_SelPopuRnkCntnt()
      // 최신자료 로딩
      this.fn_SelRntCntnt()
    },

    /*********************************************************
     * @function  fn_CheckClick
     * @notice    체크박스 클릭
    *********************************************************/
    fn_CheckClick(target) {
      // 선택된 전체 리스트갯수
      let totCnt = 0
      // 문자버튼 컨트롤을 위한 예외 카운트
      let doNotSmsCnt = 0
      let doNotDetailShowCnt = 0      

      if (this.clickedSingleItem === true) {
        this.clickedSingleItem = false

        this.$commonUtil.updateDisplay(this.$refs.container1)
        this.$commonUtil.updateDisplay(this.$refs.container2)
        this.$commonUtil.updateDisplay(this.$refs.container3)
        return
      }

      // 선택된 전체 리스트
      this.lv_SelectedItemList = []
      this.$commonUtil.updateDisplay(this.$refs.container1)
      this.$commonUtil.updateDisplay(this.$refs.container2)
      this.$commonUtil.updateDisplay(this.$refs.container3)

      // 선택된 내가사용한 리스트
      this.lv_SelectedUsedList = this.lv_myUsedCntntItems.filter(item => item.checked == true)
      // 선택된 인기 리스트
      this.lv_SelectedPopuList = this.lv_popuRnkCntntItems.filter(item => item.checked == true)
      // 선택된 최신 리스트
      this.lv_SelectedRntList = this.lv_RntCntntItems.filter(item => item.checked == true)

      // 선택된 전체갯수 5개까지허용(미리보기 + 문자보내기)
      totCnt = this.lv_SelectedUsedList.length + this.lv_SelectedPopuList.length + this.lv_SelectedRntList.length
      if (totCnt === 0) {
        this.$refs.bs10.close();
      }
      if (totCnt > 0) {
        this.$refs.bs10.open();
      }

      // 선택된 전체리스트에 추가
      for (let i = 0; i < this.lv_SelectedUsedList.length; i++) {
        this.lv_SelectedItemList.push(this.lv_SelectedUsedList[i])
      }
      for (let j = 0; j < this.lv_SelectedPopuList.length; j++) {
        this.lv_SelectedItemList.push(this.lv_SelectedPopuList[j])
      }
      for (let k = 0; k < this.lv_SelectedRntList.length; k++) {
        this.lv_SelectedItemList.push(this.lv_SelectedRntList[k])
      }

      this.$commonUtil.updateDisplay(this.$refs.container1)
      this.$commonUtil.updateDisplay(this.$refs.container2)
      this.$commonUtil.updateDisplay(this.$refs.container3)

      // 문자발송버튼 클릭 가능여부 체크  
      for (let i = 0; i < this.lv_SelectedItemList.length; i++) {
        if (!this.$bizUtil.isEmpty(this.lv_SelectedItemList[i].vdoLnkUrl)) {
          doNotSmsCnt++
        }

        if (this.fn_IsLib || this.fn_IsLOHelp) {
          doNotDetailShowCnt++
        }
      } // for i  

      if (doNotSmsCnt > 0) {
        this.lv_IsSelectedSmsContent = true
        if (this.lv_SelectedItemList.length !== doNotSmsCnt) {
          this.getStore('toast').dispatch('ADD', '동영상은 다른 안내자료와 함께 전송할 수 없습니다.')
        }
      } else {
        this.lv_IsSelectedSmsContent = false
      }

      if (doNotDetailShowCnt > 0) {
        this.getStore('toast').dispatch('ADD', '선택항목 중 라이브러리, 도움방 자료가 포함되면 상세보기만 가능합니다.')
        this.lv_IsSelectedLibraryContent = true
      } else {
        this.lv_IsSelectedLibraryContent = false
      }
    },
  
    /*********************************************************
     * @function  fn_MoreBtnClick
     * @notice    더보기 버튼 클릭
    *********************************************************/
    fn_MoreBtnClick(type) {
      let item1List = []
      let item2List = []
      let item1 = {}
      let item2 = {}
      let item3 = {}
      let pushObj = {}
      let tmpIdx = 0

      item1List = this.getStore('spStore').state.clsfSytmCntntList
      if (_.isEmpty(item1List)) return

      for(let i = 0; i < item1List.length; i++) {
        // 대분류 중 추천자료 리스트 추출
        if (item1List[i].clsfSytmId === '0100000000') {
          item1 = item1List[i]
          item2List = item1List[i].sAMClsfSytmCntntVO
          break
        }
      }

      if (_.isEmpty(item2List)) return

      for(let j = 0; j < item2List.length; j++) {
        // 추천자료 리스트 중 
        if (item2List[j].clsfSytmId === '0101000000' && type === 'USED') {
          // 내가사용한자료
          tmpIdx = j
          break
        }else if (item2List[j].clsfSytmId === '0102000000' && type === 'POP') {
          // 인기자료
          tmpIdx = j
          break
        }else if (item2List[j].clsfSytmId === '0103000000' && type === 'RNT') {
          // 최신자료
          tmpIdx = j
          break
        }
      }
      item2 = item2List[tmpIdx]
      item3 = item2List[tmpIdx].tTSA00003DVO[0]

      pushObj.item1 = item1
      pushObj.item2 = item2
      pushObj.item3 = item3


      this.$refs.bs10.close();
      this.$router.push({
        name: 'MSPSA207M',
        params: {
          callerView: this.$options.screenId,
          lv_SelectedCateObject: pushObj
        }
      })

    },


    /*********************************************************
     * @function  fn_OpenCate
     * @notice    카테고리 버튼 클릭 팝업오픈
    *********************************************************/
    fn_OpenCate (popup) {
      const lv_Vm = this

      // lv_UntySlmtClsf 빈값일 경우, '카테고리' 화면으로 이동금지
      if (_.isEmpty(lv_Vm.lv_UntySlmtClsf)) return

      let lv_Popup206 = this.$bottomModal.open(MSPSA206P, {
        properties: {
          //paramData: this.?? // param셋팅
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          popupCloseMSPSA206P: (pData) => {
            if (pData !== null) {
              this.lv_SelectedCateObject = pData
            }
            this.$bottomModal.close(lv_Popup206)
            lv_Vm.$refs.bs10.close();
            this.$router.push({
              name: 'MSPSA207M',
              params: {
                callerView: this.$options.screenId,
                lv_SelectedCateObject: pData
              }
            })
          }
        }
      })
    },

    /*********************************************************
     * @function  fn_SearchProd
     * @notice    검색버튼 클릭
    *********************************************************/
    fn_SearchProd () {
      this.$refs.bs10.close();
      this.$router.push({name: 'MSPSA204M'})
    },

/* 2차 오픈시 추가반영   화면 하단 BS 영역부문 관련 프로그램반영   */

    /*********************************************************
     * @function  fn_SaveToCouncel  (화상상담 자료저장)
     * @notice    고객목록 팝업에서 고객 선택 후 저장 버튼 클릭 시
     * Params:    고객목록 팝업에서 선택된 고객목록(List<SAGrpInfoSVO>)
     * Return:    N/A
    *********************************************************/
    fn_SaveToCouncel() {
      this.isNofacCnsl = true

      let parentsViewId = this.$options.screenId
      let custList = this.lv_SelectedCustList.slice()
      this.lv_TargetType = 'c'
        // 수신고객목록에 전달할 선택 고객목록 및 기타 파라미터 설정
      this.custListObj = [
        {targetType: this.lv_TargetType, parentsViewId: parentsViewId, custList: custList}
      ]

      this.fn_AfterCustSearch() // FOM문서 PDF변환 혹은 PDF문서 정보 저장
    },

    /*********************************************************
     * @function  fn_AfterCustSearch
     * @notice    [비대면화상상담]고객목록 팝업에서 고객 선택 후 다음 버튼 클릭 시
     * Params:    고객목록 팝업에서 선택된 고객목록
     * Return:    N/A
    *********************************************************/
    fn_AfterCustSearch() {
      const lv_Vm = this

      // 다중 선택 후 화상상담 진행시
      this.lv_IsMadeMultiItem = true

      this.lv_SelectedFomItemList = [] // PDF로 변환될 FOM 파일 저장 목록 초기화
      this.lv_SelectedPdfList = [] // 기존에 만들어진 PDF파일 담을 목록 초기화
      // 로딩바 강제 설정
      this.getStore('progress').getters.getState.isShow = true

      // 1. FOM 파일만 별도로 추출
      //    FOM 파일은 문서변화 서버를 이용해 PDF로 변환
      // 2. PDF 파일일 경우
      //    'UNTY_SLMT_DIR' 를 명시적으로 지정해 주고
      //    pdfApndFilePathNm에 저장된 'ic/20190219' 와
      //    pdfApndFileStoreNm에 저장된 '30002012030102301203213'와 같은 파일명을 조합하여 파일을 다운로드(.pdf 확장자를 붙이지 않음)
      //    -> 최종경로 : /filedown.do?path=UNTY_SLMT_DIR&filename=ic/20190220/000300336246415221102209102

      this.lv_SelectedItemList.forEach(item => {
        if (item.pdfFileYn === 'Y') { // PDF 파일
          // 로컬 테스트인 경우
          if (process.env.NODE_ENV === 'local') {
            let itemObj = {}
            itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            itemObj.nofacCnslDoctNm = item.untySlmtNm
            itemObj.eltrnDoctFileNm = item.pdfApndFileId // test용

            lv_Vm.lv_SelectedPdfList.push(itemObj)
          } else {
            let itemObj = {}
            itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR' // Context.properties에서 PDF 파일이 저장된 위치를 명시한 키값 설정
            itemObj.nofacCnslDoctNm = item.untySlmtNm
            // itemObj.eltrnDoctFileNm = item.pdfApndFileStoreNm // PDF 파일일 경우는 확장자 .pdf를 붙이지 않음
            itemObj.pdfFilePathDirNm = item.pdfApndFilePathNm // ic/20190219
            itemObj.eltrnDoctFileNm = item.pdfApndFileStoreNm

            lv_Vm.lv_SelectedPdfList.push(itemObj)
          }
        } else {
          lv_Vm.lv_SelectedFomItemList.push(item) // FOM 파일 저장
        }
      })

      // FOM 파일이 하나 이상 선택되었을 경우는 PDF 변환 진행
      if (lv_Vm.lv_SelectedFomItemList.length > 0) {
        lv_Vm.fn_CreateToPDFContnt()
      } else { // PDF문서만 선택 되었을 때
        lv_Vm.fn_InsNofacCnslData(lv_Vm.lv_SelectedPdfList)
      }
    },

    /******************************************************************************
     * @function  fn_InsNofacCnslData
     * @notice    [비대면화상상담] 자료 저장
     * Params:    비대면채널고객ID, 비대면상담문서ID, 비대면업무구분코드,비대면컨설턴트ID,문서삭제여부,비대면상담문서명,비대면상담문서경로명
     * Return:    결과 코드(1:성공 0:실패)
    ******************************************************************************/
    fn_InsNofacCnslData(docInfo) {
      const lv_Vm = this
 
      lv_Vm.isNofacCnsl = false // 초기화
      lv_Vm.lv_TargetType = 'p' // 초기화
      let pParams = {}
      let ctNofacCnslListVO = []

      for (let i = 0; i < docInfo.length; i++) { // 문서 목록
        let pParam = {}
        for (let j = 0; j < lv_Vm.custListObj[0].custList.length; j++) { // 선택 고객 목록
          pParam = {
            nofacChnlCustId: lv_Vm.custListObj[0].custList[j].chnlCustId, // 비대면채널고객ID
            nofacCnslBusnScCd: 'SA', // 비대면업무구분코드(안내자료:SA)
            nofacCnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId, // 비대면컨설턴트ID
            doctDeltYn: ' ', // 문서삭제여부
            nofacCnslDoctNm: docInfo[i].nofacCnslDoctNm, // 비대면상담문서명
            nofacCnslDoctId: docInfo[i].eltrnDoctFileNm, // 비대면상담문서ID
            nofacCnslDoctPathNm: docInfo[i].eltrnDoctFilePathNm, // 비대면상담문서경로명
            pdfFilePathDirNm: docInfo[i].pdfFilePathDirNm // 기존PDF 파일 저장 경로명 예)ic/20190219
          }

          ctNofacCnslListVO.push(pParam)
        }
      }
      pParams.ctNofacCnslListVO = ctNofacCnslListVO

      const trnstId = 'txTSSCT80S1'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then( response => {
          if (this.$commonUtil.isSuccess(response)) {
            if (!_.isEmpty(response.body)) { 
              if (response.body.resultCd === 1) {
                if (lv_Vm.custListObj[0].custList.length === 1) { // 화상상담 고객이 2명 이상이면 화상상담 자료목록 팝업 호출 
                  lv_Vm.lv_selCust = {}
                  lv_Vm.lv_selCust.custNm = lv_Vm.custListObj[0].custList[0].custNm
                  lv_Vm.lv_selCust.chnlCustId = lv_Vm.custListObj[0].custList[0].chnlCustId
                  lv_Vm.lv_selCust.age = lv_Vm.fn_CmptAge + '세' // 보험나이

                  lv_Vm.fn_nofacCnslBtnClick() // 고객별 화상상담 목록 팝업 열기   
                } else {
                  lv_Vm.getStore('toast').dispatch('ADD', '비대면상담 자료를 저장하였습니다.')
                  lv_Vm.$refs.print_bottomSheet.close();
                }
              }
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
        })
        .catch( (error) => {
          window.vue.error(error)
        })
    },
 
    /*********************************************************
     * @function  fn_OpenPopSmsCustList
     * @notice    고객선택 수신 팝업오픈
    *********************************************************/
    fn_OpenPopSmsCustList() {
      const lv_Vm = this
      this.custListObj = []

      let lv_Popup152 = lv_Vm.$bottomModal.open(MSPCM152P, {
        properties: {
          pChnlCustIds: [
            { 
              targetType: 's',
              parentsViewId: this.$options.screenId,
              custList: []
            }
          ]
        },
        listeners: {
            onPopupClose: () => {
            // 모달 닫기
            lv_Vm.$bottomModal.close(lv_Popup152)
          },
          onNextService: (pData) => {
            lv_Vm.custListObj = [{targetType: 's', parentsViewId: this.$options.screenId, custList: pData.selectList}] 
            lv_Vm.fn_OpenMSPCM128P()
          },
        }
      })
    },

    /*********************************************************
     * @function  fn_OpenMSPCM128P
     * @notice    수신고객목록 팝업오픈 (문자발송)
    *********************************************************/
    fn_OpenMSPCM128P: _.debounce(function() {
      const lv_Vm = this
      let guidList = []
      
      if (this.clickedSingleItem === true) {
        guidList.push(this.lv_SelectedSingleItem)
      } else {
        guidList = this.lv_SelectedItemList
      }

      let lv_Popup128 = lv_Vm.$bottomModal.open(MSPCM128P, {
        properties: {
          pChnlCustIds: lv_Vm.custListObj, // param셋팅
          pSelectedUntySlmtItemList: guidList , 
          pIsShowTSSSP128P: true // 안내자료에서 접근시 사용
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          onPopupClose: () => {
            lv_Vm.$bottomModal.closeAll()
          },
            // 단순 팝업 종료
          onPopupCancel: () => {
            lv_Vm.$bottomModal.close(lv_Popup128)
          },
          // 터치온 발송 프로세스 async
          onNextService: (pData) => {
            lv_Vm.$bottomModal.closeAll()

            if (!_.isEmpty(pData)) {
              lv_Vm.custListObj = pData.rtnData
              // 터치온 팝업 오픈(MSPCM154P)
              lv_Vm.fn_OpenMSPCM154P()
            }
          }
        }
      })
    }, 300),

    /*********************************************************
     * @function  fn_OpenMSPCM154P
     * @notice    터치On 문자발송 팝업 오픈
    *********************************************************/
    fn_OpenMSPCM154P: _.debounce(function () {
      const lv_Vm = this

      let lv_Popup154 = lv_Vm.$bottomModal.open(MSPCM154P, {
        properties: {
          pPage: lv_Vm.custListObj.parentsViewId,                   // 모화면 페이지명
          pSmsTargetCustList: lv_Vm.custListObj.smsTargetCustList,  // 수신고객리스트
          pMsg: lv_Vm.custListObj.msg,                              // 선택된 상용구
          pIsNotEditable: lv_Vm.custListObj.isNotEditable           // 메시지 수정가능여부
        },
        listeners: {
          onPopupClose: (pData) => { 
            if (pData.str === 'confirm') {
              lv_Vm.getStore('toast').dispatch('ADD', '발송되었습니다.')
            }
            // 모달 닫기
            lv_Vm.$bottomModal.close(lv_Popup154)
          }
        }
      })
    } , 300),

    /*********************************************************
     * @function  fn_OpenPopEmailCustList
     * @notice    고객선택 수신 팝업오픈-이메일
    *********************************************************/
    fn_OpenPopEmailCustList() {
      const lv_Vm = this

      if (this.lv_SelectedItemList.length > 5) {
        this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
        return
      }
      
      let selectPDFfile = []
      
      if (this.clickedSingleItem === true) {
        selectPDFfile = [this.lv_SelectedSingleItem]
      } else {
        selectPDFfile = this.lv_SelectedItemList
      }

      this.custListObj = [
          {
            targetType: 'e',
            parentsViewId: this.$options.screenId,
            custList: []
          }
        ]

      let lv_popup152 = lv_Vm.$bottomModal.open(MSPCM152P, {
      properties: {
      //   pChnlCustIds: [
      //    {targetType: 'e',
      //     parentsViewId: this.$options.screenId,
      //     custList: []
      //    }
      //  ],
      pChnlCustIds: lv_Vm.custListObj,
        pPDFfile : selectPDFfile
      },
      listeners: {
        onPopupClose: () => {
          // 모달 닫기
          lv_Vm.$bottomModal.close(lv_popup152)
        },
        onNextService: async (pData) => {
          // 모달 닫기 (2024.03.19 추후에 MSPCM192P emit부분 개선후 적용예정)
          await lv_Vm.$bottomModal.close(lv_popup152)
          if (!_.isEmpty(pData)) {
            lv_Vm.fn_OpenPopEmailCallback(pData, selectPDFfile)
          }
        },
      }
     })
    },

    async fn_OpenPopEmailCallback(pData, selectPDFfile) {
      const lv_Vm = this

      // 중복제거
      let custList = [] // 문자수신 고객 list
      let cnt = 0

      if (pData.selectList.length > 0) {
        for (let i = 0; i < pData.selectList.length; i++) {
          cnt = 0
          if (i === 0) {
            custList.push(pData.selectList[i])
          } else {
            for (let j = 0; j < custList.length; j++) {
              if (pData.selectList[i].chnlCustId === custList[j].chnlCustId) {
                cnt++
              }
            }

            if (cnt === 0) {
              custList.push(pData.selectList[i])
            }
          }
        } // for i
      }

      lv_Vm.custListObj = [{targetType: 'e', parentsViewId: this.$options.screenId, custList: custList}]  

      let lv_pTitle = '(광고)"' + lv_Vm.lv_SelectedItemList[0].untySlmtNm + '"'
      lv_pTitle += lv_Vm.lv_SelectedItemList.length > 1 ? '외 ' + String(lv_Vm.lv_SelectedItemList.length-1) +'건': ''
      
      let lv_pText = '고객님 안녕하세요.\n' +
                      '삼성생명 ' + this.getStore('userInfo').getters.getUserInfo.userNm + '입니다.\n' +
                      '고객님께 도움이 될 만한 자료가 있어 보내드립니다.\n' +
                      '오늘도 즐거운 하루 되세요!'
      let lv_pDataModify = true                
      if (lv_Vm.custListObj[0].custList.length > 0) {
        // 문자/이메일/DM/택배 클릭시 호출(이벤트 최종 목적지 배분)
        await lv_Vm.$cmUtil.fn_OpenMSPCM191P(lv_Vm, lv_Vm.custListObj, selectPDFfile, lv_pTitle, lv_pText, lv_pDataModify)
      }
    },

    /*********************************************************
     * @function  fn_OzConfirmBtnClicked
     * @notice    인쇄
    *********************************************************/
    fn_OzConfirmBtnClicked() {
      if (_.isEmpty(this.lv_SelectedItemList)) {
        this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
        return
      }
      
      this.pIsSelectedMultiItem = false
      this.selectItems = [] // 초기화
      this.selectItems = this.lv_SelectedItemList
      this.lv_SelectedItemListTmp = [] // 초기화

      this.lv_SelectedItemListTmp = this.selectItems.slice(0)
      if (this.lv_SelectedItemListTmp.length > 1) {
        this.pIsSelectedMultiItem = true
      }

      this.fn_OzPrintCntntList()
    },

    /*********************************************************
     * @function  fn_OzPrintCntntList
     * @notice    선택 자료 목록 인쇄 처리
    *********************************************************/
    fn_OzPrintCntntList() {
      const lv_Vm = this

      // 로컬 테스트용 코드
      if (process.env.NODE_ENV === 'local') {
        lv_Vm.lv_SelectedItemListTmp.forEach(item => {
          item.pdfApndFileStoreNm = 'test_local.pdf'
          item.pdfApndFilePathNm = ''
        })
      }

      let pParams = {
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
        pblNos: lv_Vm.lv_SpinValue, // 기본 1 매 설정 
        sACntntBasInfoSVO: lv_Vm.lv_SelectedItemListTmp,
        cnsltInfoIcluYN: 'Y' // 컨설턴트 개인정보 이미지 미 포함으로 설정됨.
      }

      const trnstId = 'txTSSSA11P9'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then( response => {
          if (this.$commonUtil.isSuccess(response)) { 
            if (!_.isEmpty(response.body)) { 
              if (response.body.sACntntSVO.length === lv_Vm.selectItems.length) {
                // 출력 건수가 선택된 아이템별 선택 매수와 동일할 경우
                lv_Vm.fn_printtostMesg() // 인쇄처리 완료 메세지  
              }
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
        })
        .catch( (error) => {
          window.vue.error(error)
        })
    },

    /*********************************************************
     * @function  fn_OpenMSPSA210P
     * @notice    스크랩팝업오픈
    *********************************************************/
    fn_OpenMSPSA210P() {
      const lv_Vm = this

      if (this.lv_SelectedItemList.length === 0) { 
        this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
        return
      }

      this.selectItems = [] // 초기화
      this.selectItems = this.lv_SelectedItemList

      let lv_Popup210 = this.$bottomModal.open(MSPSA210P, {
      properties: {
        pPage: this.$options.screenId,
        pSelectedUntySlmtItems: this.selectItems.slice() // param셋팅
      },
      listeners: {
          // 팝업 닫히면서 실행되는 함수
          popupCloseMSPSA210P: () => {
            lv_Vm.$bottomModal.close(lv_Popup210)
          }
        }
      })
    }, 

    /*********************************************************
     * @function  fn_OpenMSPSA222P
     * @notice    화상상담팝업오픈
     *********************************************************/
    fn_OpenMSPSA222P() {
      const lv_Vm = this

      if (_.isEmpty(this.lv_SelectedItemList)) {
        this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
        return
      }
      
      this.selectItems = [] // 초기화
      this.selectItems = this.lv_SelectedItemList.slice()
      
      let lv_Popup222 = this.$bottomModal.open(MSPSA222P, {
        properties: {
          pPage: this.$options.screenId,
          pTargetType: lv_Vm.lv_TargetType,
          pSelectedCustList: [],
          pSearchCustNm: ''
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          onPopupConfirm222P: (pData) => {
            if (pData !== '') {    
              lv_Vm.lv_SelectedCustList = pData
              lv_Vm.fn_SaveToCouncel()
            } else {
              lv_Vm.lv_SelectedCustList = []
            }

            lv_Vm.$bottomModal.close(lv_Popup222)
          },
          onPopupClose222P: () => {
            this.$bottomModal.close(lv_Popup222);
          }
        }
      })
    }, 

    /******************************************************************************
     * @function  fn_nofacCnslBtnClick
     * @notice    화상상담자료조회popup호출 - MSPSA063P
    ******************************************************************************/
   fn_nofacCnslBtnClick() {
      const lv_Vm = this
      const LIMITED_SMS_SELECTED = 30

      if (_.isEmpty(this.lv_SelectedCustList)) {
        this.$addSnackbar('선택 인원을 확인해주세요.')
        return
      }
      
      // 최대 30명까지 선택하도록 제한한다.
      if (this.lv_SelectedCustList.length > LIMITED_SMS_SELECTED) {
        this.$addSnackbar('최대 30명까지만 문자발송 가능.\n총선택 인원을 확인해주세요.')
        return
      } 

      // 화상상담자료조회popup호출
      let lv_Popup063 = this.$bottomModal.open(MSPSA063P, {
          properties: {
            pNofacCnslCust: lv_Vm.custListObj[0].custList[0]  //this.lv_SelectedCustList[0]    
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_Popup063);
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_Popup063);
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
    },

    /*********************************************************
     * @function  fn_print_BottomSheet_XXXX
     * @notice    인쇄 처리용 버텀 시트 제어
    *********************************************************/
    open() { 
      this.$refs.bs10.open(); 
    },
    close() { 
      this.$refs.bs10.close(); 
    },
    fn_nofaeCnsl_tostMesg() {
      this.getStore('toast').dispatch('ADD', '비대면상담 자료를 저장하였습니다.')
      this.fn_print_BottomSheet_close()
    },
    fn_printtostMesg() {
      this.getStore('toast').dispatch('ADD', '인쇄되었습니다.')
      this.fn_print_BottomSheet_close()
    },
    fn_print_BottomSheet_open() { 
      if (this.lv_SelectedItemList.length < 1) { 
        this.getStore('confirm').dispatch('ADD', '인쇄할 자료를 선택하여 주십시요.')
      } else {
        this.close()
        this.$refs.print_bottomSheet.open();
      }
    },
    fn_print_BottomSheet_close() { 
      this.$refs.print_bottomSheet.close();
      this.open()
    }, 
    fn_video_bottomsheet_open() {
      const lv_Vm = this
      let props = {
        msg: '동영상 실행시 데이터 요금이 발생될수 있으니 유의 바랍니다.',
        title: '',
        closeBtn: false,
      }

      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          content: props.msg,
          title: props.title,
          showCloseBtn: props.closeBtn
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)
            lv_Vm.fn_IncrementItemInqrFgrList('Y')
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      },
      {
        properties: {
          preventTouchClose: false
        }
      }) 
    },
    
    fn_HistoryBack () {
      // 체크박스가 있는 경우
      if ((this.lv_SelectedItemList  !== null && this.lv_SelectedItemList.length > 0)
          || (this.lv_SelectedSingleItem !== null  && this.lv_SelectedSingleItem.length > 0)) {
            let alertMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'

            let alertObj = this.$bottomModal.open(MSPBottomAlert, {
              properties: {
                type: 'B',
                content: alertMsg,
                title_pos_btn: "예",
                title_neg_btn: "아니오"
              },
              listeners: {
                onPopupConfirm: () => {
                  this.$bottomModal.close(alertObj)
                  // 특정화면 이동 혹은 이전 화면 이동
                  this.$router.go(-1)
                },
                onPopupClose: () => {
                  this.$bottomModal.close(alertObj)
                }
              }
            })
      } else {
        this.$router.go(-1) // 이전화면으로 이동
      }
    },

    fn_OnPageScrollEvent (e) {
      let target = e.target
      if(!target) return
      
      let sp = target.scrollTop
      this.scrollPosition = sp
    }
  } // ::Methods End
}
</script>

<style lang="scss" scoped>
::v-deep .chk_new--click {
  .mo-checkbox__icon-container {
    width: 48px;
    height: 48px;
    background: url(/images/btn-checked-off.svg) no-repeat right bottom;
    background-size: 28px;
    border-radius: 0;
    .mo-icon {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
</style>